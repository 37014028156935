var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.showProblemList
        ? _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { "margin-bottom": "10px" },
              attrs: { data: _vm.quesSetTable },
            },
            [
              _vm.isEdit
                ? _c("el-table-column", {
                    attrs: { width: "55" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "radio",
                                  attrs: { label: scope.row },
                                  model: {
                                    value: _vm.currentRow,
                                    callback: function ($$v) {
                                      _vm.currentRow = $$v
                                    },
                                    expression: "currentRow",
                                  },
                                },
                                [_c("span")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4188229137
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "标题", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.row.title))]
                      },
                    },
                  ],
                  null,
                  false,
                  1628289981
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", width: "180" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.createTime.substring(0, 19)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1452213926
                ),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "所选题目", width: "250" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(
                          scope.row.questionList,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(item.problemTitle) +
                                  "(" +
                                  _vm._s(item.problemId) +
                                  ")" +
                                  _vm._s(index < item.length - 1 ? ", " : "") +
                                  " "
                              ),
                            ])
                          }
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  1793298889
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "状态", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.status == "已启用"
                            ? _c(
                                "el-tag",
                                { attrs: { type: "success", effect: "dark" } },
                                [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                              )
                            : _vm._e(),
                          scope.row.status == "已禁用"
                            ? _c(
                                "el-tag",
                                { attrs: { type: "danger", effect: "dark" } },
                                [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3670893858
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showProblemList
        ? [
            _c(
              "el-table",
              {
                ref: "dragTable",
                staticClass: "tb-list",
                staticStyle: { width: "40%" },
                attrs: {
                  data: _vm.problemList,
                  border: "",
                  fit: "",
                  "highlight-current-row": "",
                  border: "",
                  fit: "",
                  "highlight-current-row": "",
                  "row-key": "problem_id",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "题号", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                        },
                      },
                    ],
                    null,
                    false,
                    3056706777
                  ),
                }),
                _c("el-table-column", {
                  attrs: { label: "ID", align: "center", prop: "problem_id" },
                }),
                _c("el-table-column", {
                  attrs: { label: "标题", align: "center", prop: "title" },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }