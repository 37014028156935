<template>
  <div>
    <!-- @selection-change="handleSelectionChange" -->

    <el-table
      v-if="!showProblemList"
      ref="multipleTable"
      :data="quesSetTable"
      style="margin-bottom: 10px"
    >
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->

      <el-table-column width="55" v-if="isEdit">
        <template slot-scope="scope">
          <el-radio class="radio" :label="scope.row" v-model="currentRow">
            <span></span>
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column label="标题" width="120">
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column label="创建时间" width="180">
        <template slot-scope="scope">
          {{ scope.row.createTime.substring(0, 19) }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="所选题目" width="250">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.questionList" :key="index">
            {{ item.problemTitle }}({{ item.problemId }}){{
              index < item.length - 1 ? ", " : ""
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="120">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.status == '已启用'"
            type="success"
            effect="dark"
          >
            {{ scope.row.status }}
          </el-tag>
          <el-tag
            v-if="scope.row.status == '已禁用'"
            type="danger"
            effect="dark"
          >
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <template v-if="showProblemList">
      <el-table
        :data="problemList"
        border
        fit
        highlight-current-row
        class="tb-list"
        style="width: 40%"
        border
        fit
        highlight-current-row
        ref="dragTable"
        row-key="problem_id"
      >
        <el-table-column label="题号" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="ID"
          align="center"
          prop="problem_id"
        ></el-table-column>
        <el-table-column
          label="标题"
          align="center"
          prop="title"
        ></el-table-column>
      </el-table>
    </template>
  </div>
</template>
<script>
import { getContestLibraryList } from "@/api/research/contest";
export default {
  props: {
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    showProblemList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quesSetTable: [],
      // multipleSelection: [],
      currentRow: null,
      problemList: [],
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.getContestLibraryList();
  },
  methods: {
    async getContestLibraryList() {
      let {
        state,
        body: { list },
      } = await getContestLibraryList({});
      if (state == "success") {
        console.log("list", list);
        this.quesSetTable = list;
        console.log("this.quesSetTable", this.quesSetTable);
        if (Object.keys(this.editData).length) {
          for (let i = 0; i < this.quesSetTable.length; i++) {
            if (this.quesSetTable[i].id == this.editData.contestLibrary.id) {
              this.currentRow = this.quesSetTable[i];
              this.problemList = this.currentRow.questionList;
            }
          }
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style scoped lang="scss"></style>
